import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import auth from '@/auth'
import "./veeValidate";
import VConsole from 'vconsole';
import cordovaApp from '@/assets/js/cordova.client.js';

if (process.env.VUE_APP_ENV !== 'production') {
  new VConsole();
} 

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

import { faUserSecret, faQrcode, faLightbulb, faArrowRight, faCaretDown,
  faDeleteLeft, faXmark, faCircleCheck, faChevronLeft, faAngleRight, 
  faTimes, faCirclePlus, faLocationCrosshairs, faMagnifyingGlass, faFloppyDisk, 
  faEnvelope, faPhone, faIdCard, faCalendar, faMap, 
  faVenusMars, faUser, faCheck, faArrowsRotate, faDiceD6, 
  faChevronUp, faArrowRightFromBracket, faHeart, faCopy, faStar, 
  faPlus, faBarcode, faRotateRight, faReceipt, faKey, 
  faCircleArrowRight, faCreditCard, faMoneyBillWave, faHourglassHalf, faCircleExclamation, 
  faExclamation, faChevronRight, faStore, faWallet, faUtensils, faTicket,
  faTriangleExclamation, faGift, faRightFromBracket} from '@fortawesome/free-solid-svg-icons'
import { faImages, faCircleXmark, faHeart as faHeartR, faBell, faCircleXmark as faCircleXmarkR, 
  faCircleCheck as faCircleCheckR, faEdit as faEditR, faPenToSquare as faPenToSquareR, faHandPointer} from '@fortawesome/free-regular-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { firestorePlugin } from 'vuefire'
import './registerServiceWorker'

Vue.use(firestorePlugin)

import VueNotification from 'vue-notification'
Vue.use(VueNotification)

import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

/* add icons to the library */
library.add(faUserSecret, faImages, faQrcode, faLightbulb, faArrowRight, 
  faCaretDown, faDeleteLeft, faXmark, faCircleCheck, faChevronLeft, 
  faAngleRight, faTimes, faCirclePlus, faCircleXmark, faLocationCrosshairs, 
  faMagnifyingGlass, faFloppyDisk, faEnvelope, faPhone, faIdCard, 
  faCalendar, faMap, faVenusMars, faUser, faCheck, 
  faArrowsRotate, faDiceD6, faChevronUp, faArrowRightFromBracket, faBell, 
  faHeart, faHeartR, faCopy, faStar, faPlus, 
  faBarcode, faRotateRight, faReceipt, faKey, faCircleArrowRight, 
  faCreditCard, faMoneyBillWave, faCircleXmarkR, faCircleCheckR, faHourglassHalf, 
  faCircleExclamation, faExclamation, faChevronRight, faStore, faEditR, faPenToSquareR, 
  faWallet, faUtensils, faTicket, faHandPointer, faTriangleExclamation, faGift, faRightFromBracket)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

function getRequest(config) {
  return window.qcsso ? window.qcsso.request(config) : axios(config)
}
Vue.prototype.$http = (config) => getRequest(config)

auth(router);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
  mounted() {
    // Initialize Cordova
    cordovaApp.initialize();
  }
}).$mount('#app')
